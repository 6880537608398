
import common from '@/mixin/common.js'
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'r-product',
  mixins: [common],
  data() {
    return {
	  industryList: [], //行业
	  countryList:[], //地区
      bgImage: require('@/assets/product/bg.png'),
      bgform: require('@/assets/product/bg_form.jpg'),
      isShowBuy: false,
      isShowSuccess: true,
	  productTitle: '',
      // selectNationItem: null,
      // selectIndustryItem: null,
      productIndex: 0,
      isCheck: false,
      input:{
        name: null,
        email: null,
        phone: null,
        work: null,
        company: null,
        suggest: null,
        country_id: undefined,
        industry_id: undefined,
      },
      chipList: [
        // {
        //   img: require("@/assets/product/ic_chip01.png"),
        //   // parameters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        //   parameters1: [1, 3, 5, 7, 9, 10],
        //   parameters2: [2, 4, 6, 8],
        //   parametersEn: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27],
        //   showParameters: false,
        //   launched: true
        // },
        {
          img: require("@/assets/product/ic_chip02.png"),
          // parameters: [1, 2, 3, 4, 5, 6, 7, 8, 9],
          parameters1: [1, 3, 5, 7, 9],
          parameters2: [2, 4, 6, 8],
          // parametersEn: [1, 3, 5, 7, 2, 4, 6, 9],
          parametersEn: [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43],
          showParameters: false,
          // launched: false
          launched: true
        }
      ],
      solutionList: [{
        img: require("@/assets/product/ic_solution01.png"),
      }, {
        img: require("@/assets/product/ic_solution02.jpg"),
      }, {
        img: require("@/assets/product/ic_solution03.jpg"),
      }]

    }
  },
  mounted() {
	  this.countryList = require('../../assets/location/location.json') //地区列表数据
	  this.industryList = require('../../assets/location/industry.json') //行业列表
    // this.getCountryList();
    // this.getIndustryList();
    // this.scrollToTop();
  },
  methods: {
    ...mapActions('product', ['getCountryList', 'getIndustryList', 'submitInfo']),
    shouParameters(params) {
      params.showParameters = !params.showParameters;
    },
    // selectNationTap(params) {
    //   console.log('# selectNationTap', params);
    //   this.selectNationItem = params;
    // },
    // selectIndustryTap(params) {
    //   console.log('# selectIndustryTap', params);
    //   this.selectIndustryItem = params;
    // },
    changeCheck() {
      this.isCheck = !this.isCheck;
    },
    async submit() {
      if (!this.input.name) {
				this.$message.warning('请输入姓名');
        return;
      }
      if (!this.input.email) {
				this.$message.warning('请输入邮箱');
        return;
      }
      const reg2 = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
      if (!reg2.test(this.input.email)) {
        this.$message.warning('邮箱格式不正确');
        return;
      }
      const reg1 = /^\d+$/;
      if (this.input.phone && !reg1.test(this.input.phone)) {
        this.$message.warning('电话必须是数字');
        return;
      }
      if (!this.input.work) {
				this.$message.warning('请输入工作头衔');
        return;
      }
      if (!this.input.company) {
				this.$message.warning('请输入公司');
        return;
      }
      if (!this.input.country_id) {
				this.$message.warning('请选择国家/地区');
        return;
      }
      if (!this.input.industry_id) {
				this.$message.warning('请选择行业');
        return;
      }
	  var url = `mailto:talent@alpsentek.com?subject=${this.productTitle}&body=
	        Name：${this.input.name || ''}
	        E-mail：${this.input.email || ''}
	        Phone No.：${this.input.phone || ''}
	        Title：${this.input.work || ''}
	        Company：${this.input.company || ''}
	        Location：${this.input.country_id || ''}
	        Industry：${this.input.industry_id || ''}
	        Your requirements：${this.input.suggest || ''}
	        `
	  window.location.href = url
      // const result = await this.submitInfo({
      //   "name": this.input.name,
      //   "email": this.input.email,
      //   "phone": this.input.phone,
      //   "position": this.input.work,
      //   "company": this.input.company,
      //   "requirement": this.input.suggest,
      //   "subscribe": this.isCheck,
      //   // "country_id": this.selectNationItem.id,
      //   "country_id": `${this.input.country_id}`,
      //   // "industry_id": this.selectIndustryItem.id,
      //   "industry_id": `${this.input.industry_id}`,
      //   "product_id": `${this.productIndex + 1}`
      // });

      // if (result) {
      //   // this.isShowBuy = false;
      //   this.isShowSuccess = true;
      //   // this.$message.success('感谢您的垂询，我们将尽快联系您');
      // }
    },
    showBuyPage(params) {
	  // console.log('点击',params)
	  this.productTitle = params || ''
      this.isShowBuy = true;
      this.isShowSuccess = false;
      // this.productIndex = params;
    },
    hideBuyPage() {
      this.isShowBuy = false;
      this.isShowSuccess = false;
    },
  },
  computed: {
    // ...mapGetters('product', ['countryList', 'industryList']),
	...mapGetters('product'),
    // selectNation: function() {
    //   if (this.selectNationItem) {
    //     return this.selectNationItem['name' + this.zhPageKey];
    //   } else {
    //     return;
    //   }
    // },
    // selectIndustry: function() {
    //   if (this.selectIndustryItem) {
    //     return this.selectIndustryItem['name' + this.zhPageKey];
    //   } else {
    //     return;
    //   }
    // }
  },

}
